<template>
  <certifications-component />
</template>

<script>
import CertificationsComponent from "@/components/admin/catalogs/CertificationsComponent";

export default {
  name: "Certifications",
  title: "Certificaciones | Baja California Health Tourism",
  components: { CertificationsComponent },
};
</script>

<style scoped></style>
